body {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
}

svg {
  margin: 0.5em;
}

.navbar-item {
  font-weight: 700;
}

.hero {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.title,
.page-title {
	font-weight: 700;
	margin-bottom: 1rem;
}
.rinmainbg{
	background-image: linear-gradient( #ff4672,#fdb1c8 );
	position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff4672;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
}

