body {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
}

svg {
  margin: 0.5em;
}

.navbar-item {
  font-weight: 700;
}

.hero {
	height: 100vh;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

.title,
.page-title {
	font-weight: 700;
	margin-bottom: 1rem;
}
.rinmainbg{
	background-image: linear-gradient( #ff4672,#fdb1c8 );
	position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff4672;
  z-index: 10000;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: white;
  -webkit-flex-direction: column;
          flex-direction: column;
}


/**
 * Copyright 2017 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
 .container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(209, 2, 88);
  background: radial-gradient(circle, rgb(229, 3, 97), #9b0134);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  font-family: sans-serif;
}

.pic {
  height:100px;
  width:100px;
}
*/

